<template>
  <div class="home">
    <div>
<!--      <div class="img"></div>-->
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_01.jpg" lazy width="100%" height="100%">
<!--      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/1.png" lazy width="100%" height="100%" class="img">-->
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_05.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_9.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_10.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗4G-BG2-B2",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>
.img{
  background: url("../../../public/images/xqy/低功耗4G-BG2-B2/images/BG2-B2_01.jpg") no-repeat center center;
  height: 100%;
  width: 100%;
  /*把背景图片放大到适合元素容器的尺寸，图片比例不变*/
  background-size:cover;
  position:absolute;
  left:0;
  top:0;
}
</style>